import BaseProductImages from '../../common.blocks/product-images'

export default class ProductImages extends BaseProductImages {
	constructor($el) {
		super($el)

		this.$hideFirst = this.$el.findElement('hide-first')
	}

	initialize() {
		super.initialize()

		this.$el.findElement('image').off('click')

		this.$el.findElement('image').on('click', (e) => {
			e.preventDefault()

			const $images = this.$el
				.findElement('image')
				.filter(
					(_, el) =>
						!$(el)
							.parent()
							.hasClass(`${ProductImages.title}__swiper--invisible`),
				)
			const items = $images
				.map((_, el) => ({ src: $(el).attr('href') }))
				.toArray()

			$.magnificPopup.open({
				type: 'image',
				items,
				gallery: {
					enabled: true,
					navigationByImageClick: true,
					preload: [0, 1],
				},
				tLoading: `${this.$el.data('loadingLabel')}...`,
				callbacks: {
					beforeOpen() {
						this.goTo($(e.currentTarget).data('imgIndex'))
					},
				},
			})
		})

		const resizeCallback = () => {
			// value taken from _variables.scss
			const tabletLandscapeWidth = 1140
			const enableDriftZoom = $(window).width() >= tabletLandscapeWidth
			const driftMethod = enableDriftZoom ? 'enable' : 'disable'
			this.drifts.forEach((drift) => drift[driftMethod]())
		}
		resizeCallback()
		$(window).on('resize', () => resizeCallback())

		this.carousel.on('reachBeginning', (self) => {
			if (this.hideFirst) {
				self.slideTo(1)
			}
		})

		this.carousel.on('resize', (self) => {
			if (self.activeIndex === 0 && this.hideFirst) {
				self.slideTo(1)
			}
		})

		if (this.hideFirst) {
			this.carousel.activeIndex = 1
		}
	}

	get hideFirst() {
		return this.$hideFirst ? this.$hideFirst.is(':hidden') : false
	}
}
