import Block from '../../common.blocks/block'

export default class ShoppingcartOption extends Block {
	static title = 'shoppingcart-option'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('applepay') && this.isApplePaySupported()) {
			this.$el.closest('.checkbox').removeModifier('hidden')
		}
	}

	isApplePaySupported() {
		return window.ApplePaySession && window.ApplePaySession.canMakePayments()
	}
}
