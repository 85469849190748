import Block from '../block'

export default class SizeGuide extends Block {
	static title = 'size-guide'

	constructor($el) {
		super($el)

		this.open = this.$el.findElement('open')
		this.overlay = this.$el.findElement('overlay')
		this.close = this.$el.findElement('close')

		this.open
			.add(this.overlay)
			.add(this.close)
			.on('click', () => this.toggleDropdown())
	}

	toggleDropdown() {
		this.$el.toggleClass('size-guide--hidden')
	}
}
