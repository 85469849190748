import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class Select extends Block {
	static title = 'select'

	constructor($el) {
		super($el)

		this.selected = this.$el.findElement('selected')

		if (this.$el.find('input:checked')) {
			const value = this.$el.find('input:checked')
			if (value.data('title')) {
				this.selected.text(value.data('title'))
			} else {
				this.selected.text(value.val())
			}
		}

		this.label = this.$el.findElement('label')
		$(document).on('click', (e) => {
			if (
				!$.contains(this.$el[0], e.target) &&
				!e.target.isEqualNode(this.$el[0])
			) {
				this.$el.removeModifier('open')
				return
			}

			if (e.target instanceof HTMLInputElement) {
				this.$el.removeModifier('open')
			} else {
				this.$el.toggleModifier('open')
			}
		})

		this.$el.on('change', (event) => {
			const value = this.$el.find('input:checked')
			if (value.data('title')) {
				this.selected.text(value.data('title'))

				if (this.$el.hasModifier('size')) {
					$(document).trigger('size:change', {
						id: event.target.value,
						name: value.data('title'),
						remove: !event.target.checked,
					})

					const count = $('input[name="count"]:checked')
					$(document).trigger('stock:check', {
						name: value.data('title'),
						stock: value.data('stock'),
						count: count ? count.val() : null,
						color_id: value.data('colorId'),
					})
				}
			} else {
				this.selected.text(value.val())
			}

			if (this.$el.hasModifier('sizecup')) {
				$(document).trigger('sizecup:change', {
					id: event.target.value,
					name: value.data('title'),
					remove: !event.target.checked,
				})

				const count = $('input[name="count"]:checked')
				$(document).trigger('stock:check', {
					name: value.data('title'),
					stock: value.data('stock') ? value.data('stock') : null,
					count: count ? count.val() : null,
					color_id: value.data('colorId'),
				})

				const sizeSelect = $(document).find('.select--size')
				$(sizeSelect).find('.select__selected').text('')

				this.cupsizes = $(document).find('.select__option--cup')
				this.cupsizes.each((index, element) => {
					if ($(element).data('size') == value.data('title')) {
						$(element).removeClass('select__option--hidden')
						$(element).find('input').prop('disabled', false)
					} else {
						$(element).addClass('select__option--hidden')
						$(element).find('input').prop('disabled', true)
					}
				})
			}

			this.$el.removeModifier('open')
		})

		$(document).on('color:change', () => {
			$('input[name="size_id"]').prop('checked', false)
			$('input[name="size_placeholder"]').prop('checked', false)
			$('.select--small .select__selected').html('')
			$('.product-info__stock-content').html(
				'Kies jouw kleur en/of maat om de levertijd te bepalen',
			)
			$('.product-info__stock').removeClass(
				'product-info__stock--warning product-info__stock--error',
			)
			$('.product-info__option--sizecup').html('')
			$('.product-info__option--cup').html('')
		})

		if (this.$el.data('colorId')) {
			$(document).on('color:change', (event, data) => {
				if (this.$el.data('colorId') == data.id) {
					this.$el.removeModifier('hidden')
				} else {
					this.$el.addModifier('hidden')
				}

				$('.size__color').each((index, el) => {
					if ($(el).data('colorId') == data.id) {
						$(el).removeClass('size__color--hidden')
					} else {
						$(el).addClass('size__color--hidden')
					}
				})
			})
		}

		if (this.$el.hasModifier('count')) {
			$(document).on('stock:check', () => {
				const value = $('input[name="size_id"]:checked')
				const color_availability = $('input[name="color_id"]:checked')

				const dropdown = this.$el.findElement('dropdown')
				dropdown.empty()
				if (
					color_availability.data('availability') == 'unavailable' &&
					value.data('stock') < 6
				) {
					$.each(new Array(value.data('stock')), (index) => {
						dropdown.append(this.createCountElements(index))
					})
					this.selected.text(1)
				} else {
					$.each(new Array(6), (index) => {
						dropdown.append(this.createCountElements(index))
					})
					this.selected.text(1)
				}
			})

			// this.$el.on('change', () => {
			// 	const value = $('input[name="size_id"]:checked')

			// 	$(document).trigger('stock:check', {
			// 		name: value.data('title'),
			// 		stock: value.data('stock'),
			// 		count: this.$el.find('input[name="count"]:checked').val(),
			// 	})
			// })
		}
	}

	createCountElements(index) {
		let element = ''
		if (index == 0) {
			element = `<label class='select__option'><input class='select__input' type='radio' name='count' checked value=${
				index + 1
			}><div class='select__replacement'>${index + 1}</div></label>`
		} else {
			element = `<label class='select__option'><input class='select__input' type='radio' name='count' value=${
				index + 1
			}><div class='select__replacement'>${index + 1}</div></label>`
		}

		return element
	}
}
