import Block from '../block'
import $ from 'jquery'

export default class Calculator extends Block {
	static title = 'calculator'

	constructor($el) {
		super($el)
		// Desktop
		this.data = new FormData()
		this.buttons = this.$el.find('.calculator__steps').children()
		this.navigation = this.$el.find('.calculator__steps-bottom span span')
		this.page = 0
		this.navClick = false

		this.braSizes = {
			AA: 'AA',
			A: 'A',
			B: 'B',
			C: 'C',
			D: 'D',
			DD: 'E',
			E: 'F',
			F: 'G',
			FF: 'H',
			G: 'I',
			GG: 'J',
			H: 'K',
			HH: 'L',
			J: 'M',
			JJ: 'N',
			K: 'O',
			KK: 'P',
		}

		const bandSizes = this.$el.find('[name=band_size]')

		if (bandSizes !== null) {
			bandSizes.each((index, el) => {
				$(el).on('change', (value) => {
					this.updateBustSizeList(value)
				})
			})
		}

		this.navigation.each((index, el) => {
			$(el).on('click', () => {
				if (this.currentStep.index() != $(el).index()) {
					this.click = index - this.currentStep.index()
					const indexremove = index + 2
					this.navClick = true

					this.toggleStep()
					$('.bottom__step:nth-child(n+' + indexremove + ')').each(
						(index, el) => {
							if (this.click < 0) {
								const checked_value = $(
									`.calculator__step:eq(${$(el).index()}) input:checked`,
								).attr('name')

								$(`.calculator__step:eq(${$(el).index()}) input:checked`).prop(
									'checked',
									false,
								)

								this.data.delete(checked_value)
								$(el).find('span span').text('')
								$(el).addModifier('hidden')
							}
						},
					)
				}
			})
		})

		this.buttons.each((index, el) => {
			$(el).on('click', () => {
				if ($(el).hasClass('calculator__prev-step')) {
					this.click = -1
				} else {
					this.click = +1
				}

				this.toggleStep(el)
				if (this.page > 2 && this.click == +1 && this.checked == true) {
					this.AddData()
				}

				$('.calculator__prev-step').removeClass('calculator__prev-step--hidden')
				$('.calculator__next-step').removeClass('calculator__next-step--hidden')
				if (this.page === 0)
					$('.calculator__prev-step').addClass('calculator__prev-step--hidden')
				if (this.page + this.click >= $('.calculator__step').length)
					$('.calculator__next-step').addClass('calculator__next-step--hidden')
			})
		})
	}

	updateBustSizeList(bandSize) {
		const bustSizesContainer = this.$el.find('.bust_sizes')

		const braSize = parseInt(bandSize.target.value)
		if (bustSizesContainer !== null) {
			bustSizesContainer.html('')

			Object.entries(this.braSizes).forEach(([key, val], index) => {
				const startBandSize = braSize + 10 + index * 2
				const endBandSize = startBandSize + 2

				const input = this.createCheckbox(
					val,
					key,
					`${startBandSize} - ${endBandSize} cm`,
				)

				bustSizesContainer.append(input)

				$(input).on('change', () => {
					this.calculateSize()
				})
			})
		}
	}

	calculateSize() {
		const bandSize = $('[name="band_size"]:checked').val()

		const sizeEu = $('[name="bust_size"]:checked').attr('data-eu')

		const sizeUk = $('[name="bust_size"]:checked').attr('data-uk')
		this.data.set('bandSize', bandSize)
		this.data.set('bustSizeEu', sizeEu)
		this.data.set('bustSizeUk', sizeUk)
	}

	createCheckbox(valueEu, valueUk, labelText) {
		const label = $('<label>')
		label.addClass('checkbox')

		const input = $('<input type="radio">')
		input.addClass('checkbox__input')
		input.attr('name', 'bust_size')
		input.attr('data-eu', valueEu)
		input.attr('data-uk', valueUk)

		const div = $('<div>')
		div.addClass('checkbox__replacement checkbox__replacement--button')

		div.html(labelText)

		label.append(input)
		label.append(div)

		return label
	}

	setStepValue() {
		const input_value = this.$el
			.find(`.calculator__step:eq(${this.page}) input:checked`)
			.val()

		const input_name = this.$el
			.find(`.calculator__step:eq(${this.page}) input:checked`)
			.attr('name')

		const input_text = this.$el
			.find(`.calculator__step:eq(${this.page}) input:checked`)
			.parent()
			.find('div')
			.text()

		if (this.click < 0) {
			this.data.delete(input_name)
		}

		if (input_value) {
			if (input_value.length === 0 && this.click == +1) {
				this.checked = false
			} else if (input_value.length > 0) {
				if (this.click < 0) {
					this.data.delete(input_name)
				} else {
					this.data.set(input_name, input_value)
					this.$el
						.find(`.bottom__step:eq(${this.page})`)
						.removeModifier('hidden')
					this.$el
						.find(`.bottom__step:eq(${this.page}) span span`)
						.text(input_text)
				}
			}
		} else if (this.click > 0) {
			this.checked = false
		}
	}

	toggleStep() {
		this.checked = true
		this.setStepValue()
		if (this.checked) {
			if (this.page < 1 && this.click < 1) {
				return
			}

			if (this.page + this.click > $('.calculator__step').length) {
				return
			}
			this.currentStep = this.$el.find(`.calculator__step:eq(${this.page})`)
			this.prev = this.$el.find(`.calculator__step:eq(${this.page})`)
			this.prev.addModifier('hidden')
			this.page = this.page + this.click
			this.next = this.$el.find(`.calculator__step:eq(${this.page})`)
			this.next.removeModifier('hidden')
		} else {
			this.handleFailure()
		}
	}

	setLoading(loading) {
		if (loading) {
			$('.calculator__loader').css('display', 'flex')
		} else {
			$('.calculator__loader').css('display', 'none')
		}
	}

	handleFailure() {
		$('header.header').next().css('display', 'none')
		$('.calculator__options').trigger(
			'message:error',
			$(`<span>U heeft geen selectie aangegeven</span>.`),
		)
	}

	AddData() {
		this.setLoading(true)
		this.data.set('page', this.page)
		$.ajax({
			method: 'POST',
			url: '/calculator',
			processData: false,
			contentType: false,
			cache: false,
			dataType: 'JSON',
			data: this.data,
		})
			.done((json) => {
				const currentStep = this.$el.find(`.calculator__step:eq(${this.page})`)

				currentStep.empty().append(json)
				if (currentStep.find('.product_option').length !== 0) {
					currentStep.addClass('calculator__view')
				} else if (currentStep.hasClass('calculator__view')) {
					currentStep.removeClass('calculator__view')
				}
			})
			.fail(() => {})
			.always(() => this.setLoading(false))
	}
}
