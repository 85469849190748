import Block from '../block'
import $ from 'jquery'

export default class Bestsellers extends Block {
	static title = 'bestsellers'

	constructor($el) {
		super($el)

		this.tags = this.$el.find('.product-tags__tag')
		this.tags.each((index, el) => {
			$(el).on('click', () => {
				if (!$(el).hasModifier('active')) {
					this.active = this.$el.find('.product-tags__tag--active')
					this.active.removeModifier('active')
					this.tagClicked = $(el).text().trim().replace(/\s/g, '')
					$(el).addModifier('active')
					this.$el
						.find('.carousel')
						.not(this.$el.find('.carousel.' + this.tagClicked))
						.hide()
						.addClass('carousel--hidden')
					this.$el.find('.carousel.' + this.tagClicked).show()
				}

				this.$el.find('.carousel').each((index, el) => {
					$(el)[0].swiper.autoplay.stop()
				})

				setTimeout(() => {
					const currentCarousel = this.$el.find('.carousel:visible')
					if (currentCarousel) {
						currentCarousel[0].swiper.autoplay.start()
					}
				}, 500)
			})
		})
	}
}
