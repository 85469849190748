import $ from 'jquery'

import Block from '../block'

export default class DataLayer extends Block {
	static title = 'data-layer'

	constructor($el) {
		super($el)

		try {
			this.data = JSON.parse(this.$el.text())
		} catch (e) {
			this.data = null
		}

		$(document).on('product:view-item', () => {
			this.viewItem(this.data)
		})
		$(document).on('product:add-to-wishlist', () =>
			this.addToWishlist(this.data.product),
		)

		$(document).on('shoppingcart:add-to-cart', (event, dataType) =>
			this.addToCart(this.data, dataType),
		)
		$(document).on('shoppingcart:view-cart', () => this.viewCart(this.data))
		$(document).on('shoppingcart:remove-from-cart', () =>
			this.removeFromCart(this.data),
		)

		$(document).on('checkout:add-shipping-info', (event, shippingType) =>
			this.addShippingInfo(shippingType, this.data),
		)
		$(document).on('checkout:add-payment-info', (event, paymentType) =>
			this.addPaymentInfo(paymentType, this.data),
		)
		$(document).on('checkout:begin-checkout', () =>
			this.beginCheckout(this.data),
		)

		$(document).on('shoppingcart:event', (event, data) =>
			this.shoppingcartAddRemove(data),
		)

		if ($('input[name="sc_order_id"]').length) {
			this.purchase(this.data)
		}
	}

	push(payload) {
		// if (process.env.NODE_ENV === 'development') {
		// 	// eslint-disable-next-line no-console
		// 	console.log(
		// 		'%c 🚀 DataLayer event ',
		// 		'background-color: #4285F4; color: white; margin: 10px 5px; line-height: 20px;display: inline-block;',
		// 		payload,
		// 	)
		// } else {
		window.dataLayer.push(payload)
		// }
	}

	checkDataLayer() {
		// if (process.env.NODE_ENV === 'development') {
		// 	return true
		// }
		// return this.data !== null && 'dataLayer' in window
		return this.data !== null && (window.dataLayer = window.dataLayer || [])
	}

	viewItem(data) {
		const color = $('input[name="ga4-color"]').val()
		const size = $('input[name="ga4-size"]').val()

		if (this.checkDataLayer()) {
			this.push({
				event: 'view_item',
				ecommerce: {
					currency: 'EUR',
					value: data.product.quantity * data.product.price,
					items: [
						{
							item_id: `${data.product.item_id}-${color}-${size}`,
							item_name: data.product.item_name,
							currency: 'EUR',
							item_brand: data.product.item_brand,
							item_category: data.product.item_category,
							item_variant: data.product.item_variant,
							price: data.product.price,
							quantity: data.product.quantity,
						},
					],
				},
			})
		}
	}

	addToWishlist(data) {
		const color = $('input[name="ga4-color"]').val()
		const size = $('input[name="ga4-size"]').val()

		if (this.checkDataLayer()) {
			this.push({
				event: 'add_to_wishlist',
				ecommerce: {
					currency: 'EUR',
					value: data.quantity * data.price,
					items: [
						{
							item_id: `${data.item_id}-${color}-${size}`,
							item_name: data.item_name,
							currency: 'EUR',
							item_brand: data.item_brand,
							item_category: data.item_category,
							item_variant: data.item_variant,
							price: data.price,
							quantity: data.quantity,
						},
					],
				},
			})
		}
	}

	addToCart(data, dataType) {
		if (dataType === 'product') {
			data = data.product
		}

		const color = $('input[name="ga4-color"]').val()
		const size = $('input[name="ga4-size"]').val()

		if (this.checkDataLayer()) {
			this.push({
				event: 'add_to_cart',
				ecommerce: {
					currency: 'EUR',
					value: data.quantity * data.price,
					items: [
						{
							item_id: `${data.item_id}-${color}-${size}`,
							item_name: data.item_name,
							currency: 'EUR',
							item_brand: data.item_brand,
							item_category: data.item_category,
							item_variant: data.item_variant,
							price: data.price,
							quantity: data.quantity,
						},
					],
				},
			})
		}
	}

	viewCart(data) {
		let productArray = []
		$.each(data.shoppingcart.items, function (index, value) {
			const product = {
				item_id: value.item_id,
				item_name: value.item_name,
				currency: 'EUR',
				item_brand: value.item_brand,
				item_category: value.item_category,
				item_variant: value.item_variant,
				price: value.price,
				quantity: value.quantity,
			}
			productArray.push(product)
		})

		if (this.checkDataLayer()) {
			this.push({
				event: 'view_cart',
				ecommerce: {
					currency: 'EUR',
					value: data.shoppingcart.total,
					items: productArray,
				},
			})
		}
	}

	removeFromCart(data) {
		let productArray = []
		$.each(data.shoppingcart.items, function (index, value) {
			const product = {
				item_id: value.item_id,
				item_name: value.item_name,
				currency: 'EUR',
				item_brand: value.item_brand,
				item_category: value.item_category,
				item_variant: value.item_variant,
				price: value.price,
				quantity: value.quantity,
			}
			productArray.push(product)
		})

		if (this.checkDataLayer()) {
			this.push({
				event: 'remove_from_cart',
				ecommerce: {
					currency: 'EUR',
					value: data.quantity * data.price,
					items: [
						{
							item_id: data.item_id,
							item_name: data.item_name,
							currency: 'EUR',
							item_brand: data.item_brand,
							item_category: data.item_category,
							item_variant: data.item_variant,
							price: data.price,
							quantity: data.quantity,
						},
					],
				},
			})
		}
	}

	addShippingInfo(shippingType, data) {
		data = data.shoppingcart

		let productArray = []
		$.each(data.items, function (index, value) {
			const product = {
				item_id: value.item_id,
				item_name: value.item_name,
				currency: 'EUR',
				item_brand: value.item_brand,
				item_category: value.item_category,
				price: value.price,
				quantity: value.quantity,
			}
			productArray.push(product)
		})

		if (this.checkDataLayer()) {
			this.push({
				event: 'add_shipping_info',
				ecommerce: {
					currency: 'EUR',
					value: data.total,
					shipping_tier: shippingType.shipping_tier,
					items: productArray,
				},
			})
		}
	}

	addPaymentInfo(paymentType, data) {
		data = data.shoppingcart

		let productArray = []
		$.each(data.items, function (index, value) {
			const product = {
				item_id: value.item_id,
				item_name: value.item_name,
				currency: 'EUR',
				item_brand: value.item_brand,
				item_category: value.item_category,
				price: value.price,
				quantity: value.quantity,
			}
			productArray.push(product)
		})

		if (this.checkDataLayer()) {
			this.push({
				event: 'add_payment_info',
				ecommerce: {
					currency: 'EUR',
					value: data.total,
					payment_type: paymentType.payment_type,
					items: productArray,
				},
			})
		}
	}

	beginCheckout(data) {
		data = data.shoppingcart

		let productArray = []
		$.each(data.items, function (index, value) {
			const product = {
				item_id: value.item_id,
				item_name: value.item_name,
				currency: 'EUR',
				item_brand: value.item_brand,
				item_category: value.item_category,
				price: value.price,
				quantity: value.quantity,
			}
			productArray.push(product)
		})

		if (this.checkDataLayer()) {
			this.push({
				event: 'begin_checkout',
				ecommerce: {
					currency: 'EUR',
					value: data.total,
					items: productArray,
				},
			})
		}
	}

	purchase(data) {
		data = data.order

		let productArray = []
		$.each(data.items, function (index, value) {
			const product = {
				item_id: value.item_id,
				item_name: value.item_name,
				currency: 'EUR',
				item_brand: value.item_brand,
				item_category: value.item_category,
				item_variant: value.item_variant,
				price: value.price,
				quantity: value.quantity,
			}
			productArray.push(product)
		})

		if (this.checkDataLayer()) {
			this.push({
				event: 'purchase',
				ecommerce: {
					currency: 'EUR',
					transaction_id: data.transaction_id,
					value: data.value,
					shipping: data.shipping,
					tax: data.tax,
					items: productArray,
				},
			})
		}
	}

	shoppingcartAddRemove(data) {
		if (this.checkDataLayer()) {
			this.push({
				event: data.event,
				ecommerce: {
					currency: 'EUR',
					value: data.quantity * data.price,
					items: [
						{
							item_id: data.item_id,
							item_name: data.item_name,
							currency: 'EUR',
							item_category: data.item_category,
							item_brand: data.item_brand,
							item_variant: data.item_variant,
							price: data.price,
							quantity: data.quantity,
						},
					],
				},
			})
		}
	}
}
