import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class FilterSizes extends Block {
	static title = 'filter-sizes'

	constructor($el) {
		super($el)

		this.labels = this.$el.findElement('labels')

		this.labels.each((index, element) => {
			$(element).on('click', () => {
				const inputs = $(element).siblings()
				inputs.each((index, el) => {
					if ($(el).is(':checked')) {
						$(el).prop('checked', false)
					} else {
						$(el).prop('checked', true)
					}
				})
				$('form.filters').trigger('submit')
			})
		})
	}
}
