import Block from '../block'

export default class ShoppingcartAddress extends Block {
	static title = 'shoppingcart-address'

	constructor($el) {
		super($el)

		this.checkbox = this.$el.find('input[type="checkbox"]')
		this.mdc = this.$el.find('.mdc-select--invoice-address')
		this.input = this.$el.find('select[name="invoiceaddress_id"]')

		this.checkbox.on('change', () => {
			if (this.checkbox.is(':checked')) {
				this.mdc.addClass('mdc-select--disabled')
				this.input.prop('disabled', true)
			} else {
				this.mdc.removeClass('mdc-select--disabled')
				this.input.prop('disabled', false)
			}
		})
	}
}
