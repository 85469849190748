import Filters from '../../common.blocks/filters'

export default class CustomFilter extends Filters {
	static title = 'filters'

	constructor($el) {
		super($el)

		this.toggle = this.$el.find('.filters__toggle')
		this.mobileFilter = this.$el.find('.filters__mobile')
		this.mobileClose = this.$el.find('.filters__mobile-close')

		this.toggle.on('click', () => {
			this.mobileFilter.toggleClass('filters__mobile--hidden')
		})

		this.mobileClose.on('click', () => {
			this.mobileFilter.toggleClass('filters__mobile--hidden')
		})
	}
}
