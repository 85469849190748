import 'vanilla-cookieconsent'

try {
	$(function () {
		const data = JSON.parse($('#cookie-consent').text())

		const { modal, essential, analytics, info } = data

		window.initCookieConsent().run({
			current_lang: 'en',
			autoclear_cookies: true,
			page_scripts: true,
			mode: 'opt-out',
			languages: {
				en: {
					consent_modal: {
						title: modal.title,
						description: `${modal.description} <button type="button" data-cc="c-settings" class="cc-link">${modal.descriptionButton}</button>`,
						primary_btn: {
							text: modal.acceptAll,
							role: 'accept_all', // 'accept_selected' or 'accept_all'
						},
						secondary_btn: {
							text: modal.acceptNecessary,
							role: 'accept_necessary', // 'settings' or 'accept_necessary'
						},
					},
					settings_modal: {
						title: modal.preference,
						save_settings_btn: modal.saveSettings,
						accept_all_btn: modal.acceptAll,
						reject_all_btn: modal.acceptNecessary,
						close_btn_label: modal.close,
						cookie_table_headers: [
							{ col1: modal.col1 },
							{ col2: modal.col2 },
							{ col3: modal.col3 },
							{ col4: modal.col4 },
						],
						blocks: [
							{
								title: `${modal.blockTitle} 📢`,
								description: `${modal.blockDescription} <a href="/klantenservice/privacy" class="cc-link">privacy policy</a>.`,
							},
							{
								title: essential.title,
								description: essential.description,
								toggle: {
									value: 'necessary',
									enabled: true,
									readonly: true,
								},
								cookie_table: [
									{
										col1: 'PHPSESSID',
										col2: window.location.host,
										col3: essential.phpSessIdExpirationDate,
										col4: essential.phpSessIdDescription,
									},
									{
										col1: 'cc_cookie',
										col2: window.location.host,
										col3: essential.ccCookieExpirationDate,
										col4: essential.ccCookieDescription,
									},
								],
							},
							{
								title: analytics.title,
								description: analytics.description,
								toggle: {
									value: 'analytics', // your cookie category
									enabled: true,
									readonly: false,
								},
								cookie_table: [
									{
										col1: '^_ga',
										col2: 'google.com',
										col3: analytics.gaExpirationDate,
										col4: analytics.gaDescription,
										is_regex: true,
									},
									{
										col1: '_gid',
										col2: 'google.com',
										col3: analytics.gidExpirationDate,
										col4: analytics.gidDescription,
									},
									{
										col1: '_ga_<container-id>',
										col2: 'google.com',
										col3: analytics.gidIdExpirationDate,
										col4: analytics.gidIdDescription,
									},
									{
										col1: '_gac_gb_<container-id>',
										col2: 'google.com',
										col3: analytics.gacGbExpirationDate,
										col4: analytics.gacGbDescription,
									},
								],
							},
							{
								title: info.title,
								description: `${info.description} <a class="cc-link" href="/contact">${info.contact}</a>.`,
							},
						],
					},
				},
			},
		})
	})
} catch (e) {
	// eslint-disable-next-line no-console
	console.error('cookie consent could not initialise', e)
}
