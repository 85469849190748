import Block from '../block'

export default class ActiveFilters extends Block {
	static title = 'active-filters'

	constructor($el) {
		super($el)

		this.replacement = this.$el.find('.active-filters__replacement--price')
		this.input = this.$el.find('input[name="filters[price][]"]')
		let values = null

		this.$el.on('click', (e) => {
			$(e.target)
				.parent('label')
				.find('input:first-child')
				.removeAttr('checked')
			$(e.target).parent('label').find('input:first-child').remove()
			$('.form').submit()
		})

		$('.form').submit(function (e) {
			this.remove = $(e?.originalEvent?.submitter)
			if (this.remove.hasClass('filters__delete')) {
				this.remove.parent('.filters__container').remove()
			}
		})

		if (this.input[0]) {
			values = this.input.val().match(/\d+/g)
		}

		if (this.replacement[0] && values) {
			this.replacement.html(
				`€${values[0]} - €${values[1]}<i class="far fa-times"></i>`,
			)
		}
	}
}
