import Block from '../block'
import $ from 'jquery'

export default class ProductOverview extends Block {
	static title = 'product-overview'

	static offset = 200

	#filter = {
		color: [],
		size: [],
	}

	constructor($el) {
		super($el)

		this.page = 1
		this.loading = false

		this.offset = 200
		this.bottom = this.$el.position().top + this.$el.height() - this.offset

		// use a native event listener, so we can use passive event handlers
		this.infiniteScrollListener = () => this.handleInfiniteScroll()
		window.addEventListener('scroll', this.infiniteScrollListener, {
			passive: true,
		})
	}

	/**
	 * Executes an infinite scroll.
	 *
	 * This should be invoked when the next page must be loaded, due to the user
	 * scrolling to a certain spot.
	 *
	 * This must also be invoked whenever changing the filters or sorting.
	 * When changing the filters or sorting, `true` must be passed.
	 *
	 * @param resetPagination {boolean}
	 */
	handleInfiniteScroll(resetPagination = false) {
		if (this.loading || $(window).scrollTop() < this.bottom) {
			return null
		}

		// activate the loader
		this.loading = true
		this.$el.findElement('loader').addModifier('active')

		if (resetPagination) {
			this.page = 1
			this.$el.findElement('content').html('')

			// scroll to top because we're starting at page 1
			window.scrollTo({
				top: this.$el.findElement('content').scrollTop(),
				behavior: 'smooth',
			})
		} else {
			// set active page to next page
			this.page = this.page + 1
		}

		$.get(this.url, (html) => {
			// parse the response
			const content = $('<div>', { html }).find(
				'.product-overview__content > *',
			)
			this.$el.findElement('content').append(content)

			// reset values
			this.loading = false
			this.bottom = this.$el.position().top + this.$el.height() - this.offset

			// stop the loader
			this.$el.findElement('loader').removeModifier('active')

			// we've reached the end, disable infinite scroll
			if (content.length === 0) {
				window.removeEventListener('scroll', this.infiniteScrollListener, {
					passive: true,
				})
			}
		})
	}

	/**
	 * Constructs a URL based on the current filters, page and sorting options
	 * This is the URL that is used to fetch new products.
	 *
	 * @returns {string}
	 */
	get url() {
		const url = new URL(window.location.href)
		const params = new URLSearchParams()

		params.set('page', this.page)
		url.search = params.toString()

		return url.toString()
	}
}
