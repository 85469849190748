import Block from '../block'
import $ from 'jquery'
import noUiSlider from 'nouislider'

export default class PriceFilter extends Block {
	static title = 'price-filter'

	constructor($el) {
		super($el)

		this.inputLower = this.$el.findElement('input--lower')
		this.inputUpper = this.$el.findElement('input--upper')

		this.currentValues = $('.price-values')
		const values = this.currentValues.val().match(/\d+/g)

		this.inputLower.val(values[0])
		this.inputUpper.val(values[1])

		const slider = this.$el.findElement('slider')
		noUiSlider.create(slider[0], {
			start: [values[0], values[1]],
			connect: true,
			range: {
				min: 0,
				max: 500,
			},
		})

		this.valueLower = $('.noUi-handle-lower')
		this.valueUpper = $('.noUi-handle-upper')

		this.inputLower.add(this.inputUpper).on('change', () => {
			this.currentValues.val(
				`>= ${this.inputLower.val()}|<= ${this.inputUpper.val()}`,
			)
			slider[0].noUiSlider.set([this.inputLower.val(), this.inputUpper.val()])

			$(document).trigger('filter:priceSlider')
		})

		slider[0].noUiSlider.on('change', () => {
			const positions = slider[0].noUiSlider.get()
			const lower = Math.round(positions[0])
			const upper = Math.round(positions[1])

			this.currentValues.val(`>= ${lower}|<= ${upper}`)

			$(document).trigger('filter:priceSlider')
		})
	}
}
