import Block from '../../common.blocks/product-info'
import $ from 'jquery'

export default class ProductInfo extends Block {
	static title = 'product-info'

	constructor($el) {
		super($el)

		this.selectedSize = this.$el.findElement('option--size')
		this.selectedSizeCup = this.$el.findElement('option--sizecup')
		this.selectedCup = this.$el.findElement('option--cup')
		this.selectedColor = this.$el.findElement('option--color')
		this.sizeType = this.$el.find('input[name="Regio"]')
		this.stock = this.$el.findElement('stock')
		this.sizeCupSelect = this.$el.find(
			'.select--sizecup .select__dropdown--inner',
		)
		this.sizeSelect = this.$el.find('.select--size .select__dropdown--inner')
		this.sizeType.each((index, el) => {
			$(el).on('change', () => {
				const sizeTypeInput = $(document).find('#size_type_' + $(el).attr('id'))
				sizeTypeInput.prop('checked', true)
				sizeTypeInput.parent().trigger('submit')
			})
		})

		// if an user selects a new color, we want to display the corresponding size select
		$(document).on('size:change', (event, data) =>
			this.handleSizeChange(event, data),
		)
		this.sizeSelect.each((index, el) => {
			if ($(el).data('colorId')) {
				$(document).on('color:change', (event, data) => {
					if ($(el).data('colorId') == data.id) {
						$(el).removeModifier('hidden')
					} else {
						$(el).addModifier('hidden')
					}
				})
			}
		})

		$(document).on('sizecup:change', (event, data) =>
			this.handleSizeCupChange(event, data),
		)

		this.sizeCupSelect.each((index, el) => {
			if ($(el).data('colorId')) {
				$(document).on('color:change', (event, data) => {
					if ($(el).data('colorId') == data.id) {
						$(el).removeModifier('hidden')
					} else {
						$(el).addModifier('hidden')
					}
				})
			}
		})

		$(document).on('color:change', (event, data) =>
			this.handleColorChange(event, data),
		)

		$(document).on('stock:check', (event, data) => this.stockCheck(event, data))
		this.stock.each((index, el) => {
			if ($(el).data('colorId')) {
				$(document).on('color:change', (event, data) => {
					$(el).attr('class', 'product-info__stock')

					if ($(el).data('colorId') == data.id) {
						$(el).removeModifier('hidden')
					} else {
						$(el).addModifier('hidden')
					}

					this.stockCheck(null, {
						name: null,
						stock: null,
						count: null,
					})
				})
			}
		})
	}

	handleSizeChange(event, data) {
		this.selectedSize.text(data.name)
		this.selectedCup.text(data.name)
		// this.price.text(data.price + ',-')
	}

	handleSizeCupChange(event, data) {
		this.selectedSizeCup.text(data.name)
	}

	handleColorChange(event, data) {
		this.selectedColor.text(data.name)
	}

	stockCheck(event, data) {
		const availability = $('.color__input:checked').data('availability')
		const stockElement = this.$el.find('.product-info__stock')
		const stock = stockElement.length > 1 ? stockElement : this.stock
		if (data.stock != null) {
			if (availability == 'unavailable' && data.stock <= 0) {
				let message = $(stock).data('noStock')

				$(stock).addModifier('warning')

				const content = this.$el.findElement('stock-content')
				content.text(message)
			} else if (data.stock == 1) {
				let message = $(stock).data('selectedStockOne')
				message = message.replace('%', data.stock)

				$(stock).addModifier('warning')

				const content = this.$el.findElement('stock-content')
				content.text(message)
			} else if (data.stock > 1 && data.stock <= 10) {
				let message = $(stock).data('selectedStockMore')
				message = message.replace('%', data.stock)

				$(stock).addModifier('success')

				const content = this.$el.findElement('stock-content')
				content.text(message)
			} else if (availability == 'available') {
				let message = $(stock).data('deliveryTime')
				message = message.replace('%', data.stock)

				$(stock).addModifier('success')

				const content = this.$el.findElement('stock-content')
				content.text(message)
			} else if (data.count > data.stock) {
				let message = $(stock).data('selectedMoreThanStock')
				message = message.replace('%', data.stock)

				$(stock).addModifier('warning')

				const content = this.$el.findElement('stock-content')
				content.text(message)
			} else if (data.stock > 0) {
				let message = $(stock).data('stockStandard')
				message = message.replace('%', data.stock)

				$(stock).addModifier('success')

				const content = this.$el.findElement('stock-content')
				content.text(message)
			} else if (data.stock <= 0) {
				let message = $(stock).data('noStock')

				$(stock).addModifier('warning')

				const content = this.$el.findElement('stock-content')
				content.text(message)
			}
		} else {
			let message = $(stock).data('selectSizeColor')

			$(stock).addModifier('success')

			const content = this.$el.findElement('stock-content')
			content.text(message)
		}
	}
}
