import Block from '../block'
import debounce from 'lodash.debounce'

const MINIMAL_QUERY_LENGTH = 3

export default class Search extends Block {
	static title = 'search'

	constructor($el) {
		super($el)

		this.overlay = this.$el.findElement('overlay')
		this.dropdown = this.$el.findElement('dropdown')

		// this block requires this data attribute to initialize
		// otherwise it will fall back to the default search
		if (this.$el.data('autocomplete')) {
			this.$el.findElement('input').keyup(
				debounce((event) => this.handleSearchInput(event), 350, {
					leading: true,
				}),
			)

			this.overlay.on('click', () => {
				this.dropdown.removeModifier('active')
				this.overlay.removeModifier('active')
			})
		}

		$(document).on('search:open', () => {
			this.$el.addModifier('active')
			this.overlay.addModifier('active')
			// this.$el.findElement('input').focus()
		})

		$(document).on('search:close', () => {
			this.$el.removeModifier('active')
			this.dropdown.removeModifier('active')
			this.overlay.removeModifier('active')
		})

		this.$el.on('focusout', () => {
			this.$el.removeModifier('active')
		})
	}

	handleSearchInput(event) {
		// enter press = search
		if (event.keyCode === 13) {
			return [
				this.dropdown.addModifier('active'),
				this.overlay.addModifier('active'),
			]
		}

		const query = event.target.value

		if (query.length === 0 || event.keyCode === 27) {
			return [
				this.dropdown.removeModifier('active'),
				this.overlay.removeModifier('active'),
			]
		}

		if (query.length < MINIMAL_QUERY_LENGTH) {
			return [
				this.dropdown.removeModifier('active'),
				this.overlay.removeModifier('active'),
			]
		}

		$.getJSON(`/search?autocomplete&term=${encodeURIComponent(query)}`)
			.done((json) => {
				this.dropdown.html(json.html).addModifier('active')
				this.overlay.addModifier('active')
			})
			.fail((error) => {
				$(document).trigger('message:error', error)
			})
	}
}
