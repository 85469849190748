import Block from '../block'

export default class Text extends Block {
	static title = 'text'

	constructor($el) {
		super($el)

		this.toggle = this.$el.findElement('toggle')
		this.readmore = this.$el.findElement('read-more')

		this.toggle.on('click', () => {
			this.readmore.removeModifier('hidden')
		})
	}
}
