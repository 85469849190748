import Popup from '../popup'
import $ from 'jquery'

export default class NewsletterPopup extends Popup {
	static title = 'popup'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('newsletter')) {
			this.$el.findElement('close').on('click', () => {
				this.data = new FormData()
				this.data.append('ajax', true)
				this.data.append('cookie', 'newsletter_popup')
				this.data.append('cookie_expire', '+30 days')

				$.ajax({
					method: 'POST',
					processData: false,
					contentType: false,
					cache: false,
					url: '/cookie',
					data: this.data,
				})
					.done(this.handleSuccess)
					.fail(this.handleFailure)
			})
		}
	}

	handleFailure(error) {
		$(document).trigger('message:error', error.message)
	}

	handleSuccess(json) {
		if (typeof json.error === 'string') {
			$(document).trigger('message:error', json.error)
		}

		if (typeof json.message === 'string') {
			$(document).trigger('message:success', json.message)
		}
	}
}
