import 'magnific-popup'
import Swiper from 'swiper/bundle'
import Drift from 'drift-zoom'

import Block from '../block'

export default class ProductImages extends Block {
	static title = 'product-images'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('hidden') === false) {
			this.initialize()
		}

		// if an user selects a new color, we want to display the corresponding product images
		$(document).on('color:change', (event, data) =>
			this.handleColorChange(data),
		)
	}

	handleColorChange(data) {
		if (
			this.$el.data('colorId').toString() === data.id ||
			this.$el.data('colorId') == 'static'
		) {
			this.$el.removeModifier('hidden')

			this.initialize()
		} else {
			this.$el.addModifier('hidden')
		}
	}

	/**
	 * Start the carousel and the image lightbox
	 */
	initialize() {
		// initialize the image gallery
		this.$el.findElement('image').magnificPopup({
			type: 'image',
			gallery: {
				enabled: true,
				navigationByImageClick: true,
				preload: [0, 1],
			},

			tLoading: `${this.$el.data('loadingLabel')}...`,
		})

		this.drifts = []
		this.$el.findElement('image').each((_index, el) => {
			this.drifts.push(
				new Drift(el, {
					paneContainer: $(el).parent().get(0),
					sourceAttribute: 'href',
				}),
			)
		})

		const thumbnail = new Swiper(this.$el.findElement('thumbnails').get(0), {
			spaceBetween: 10,
			slidesPerView: 6,
			freeMode: true,
			watchSlidesProgress: true,

			navigation: {
				nextEl: '.thumbnails__next',
				prevEl: '.thumbnails__prev',
			},
		})

		this.thumbnail = thumbnail

		// initialize the carousel that displays all images for one color
		const carousel = new Swiper(
			this.$el.findElement('swiper--product').get(0),
			{
				slidesPerView: 1,

				navigation: {
					nextEl: '.product__next',
					prevEl: '.product__prev',
				},

				thumbs: {
					swiper: this.thumbnail,
				},
			},
		)

		this.carousel = carousel

		this.$el.findElement('bullet').click(function () {
			carousel.slideTo($(this).index(), 300)
		})
	}
}
